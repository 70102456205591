import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from "gatsby"
import { FontAwesomeSvgIcon } from "react-fontawesome-svg-icon"
import { faChevronRight, faVideo, faTimesCircle, faGlobeAmericas } from "@fortawesome/free-solid-svg-icons"
import produce from 'immer'

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import Layout2Columns from "../components/layout/Layout2Columns";
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import HoverButton from "../components/buttons/HoverButton"
import VideoShort from "../components/VideoShort"
import { devices, colors } from "../settings"
import Overlay from "../components/Overlay"

const Posts = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`
const Post = styled.li`
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  display: flex;

  @media ${devices.mobile} {
    display: block;
  }

  > div.preview {
    min-width: 250px;
    flex: 0;

    img {
      border-radius: 5px;
      width: 100%;
      height: auto;

      @media ${devices.mobile} {
        border-radius: 0px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }
    }
  }
  > div {
    flex: 1;
    padding: 10px;

    h2 {
      margin: 0px;
      padding: 0px;
      margin-bottom: 5px;
    }
    small {
      display: block;
      color: #999;
      margin-bottom: 10px;
    }
    p {
      margin-top: 0px;
    }
    
  }

`
const Categories = styled.div`
  h3 {
    text-align: center;
    background-color: #9c86ff;
    color: white;
    padding: 8px;
    margin: 0px;
    font-size: 120%;
    font-weight: normal;
  }
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      padding: 5px;
      cursor: pointer;

      &.selected {
        background-color: #ccc !important;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
`
const Links = styled.div`
  margin-top: 20px;

  > a {
      display: flex;
      align-items: center;      
      padding: 5px;
      text-decoration: none;
      margin-bottom: 4px;
      
    &:hover {
        background-color: #eee;
        text-dedcoration: underline;        
    }
  }
`
const Videos = styled.div`
  display: flex;
  gap: 10px;
`

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    {
        posts: allMysqlSignclopediaPosts(sort: {fields: title, order: ASC}) {
            edges {
                post: node {
                    id
                    name
                    title
                    added_date_formatted
                    preview_url
                    video_url
                    description
                    summary
                    category_ids                    
                }
            }
        }
        links: allMysqlBlogLinks {
            edges {
                link: node {
                    id
                    blog_name
                    type
                    url
                }
            }
        }           
        categories: allMysqlSignclopediaCategories {
          edges {
              category: node {
                  id
                  category_id
                  name
                  title
                  posts
              }
          }
      }        
    }
  `);

  let formattedPosts = data.posts.edges.map(p => p.post);

  for (let post of formattedPosts) {
    post.categories = post.category_ids ? post.category_ids.split(",").map(c => parseInt(c)) : [];

    post.links = data.links.edges.filter(l => l.link.blog_name == post.name).map(l => l.link);
  }

  //const [search, setSearch] = useState(null);
  const [posts, setPosts] = useState(formattedPosts)
  const [videoPost, setVideoPost] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [categories, setCategories] = useState(data.categories.edges)

  useEffect(
    () => {
      if (categoryId == 0)
        setPosts(formattedPosts);
      else
        setPosts(formattedPosts.filter(p => p.categories.indexOf(categoryId) != -1));
    }, 
    [categoryId]
); 

  return (
    <>
      <SEO page="signencyclopedia" title="Sign Encyclopedia" />
      <Page 
        breadcrumb={[
              { url: "/", title: "Home"},
              { url: null, title: "Sign Encyclopedia"}
          ]}
      >
        <PageHeader title="Sign Encyclopedia" />

        <Layout2Columns align="top" left={75} right={25}>
          <div>
            <Posts>
                {posts.map((post) => (
                  <Post key={post.id}>
                      <div className="preview">
                        {post.preview_url && 
                            <GoogleImage root required lazy src={post.preview_url} />
                        }
                      </div>
                      <div>
                        <h2>{post.title}</h2>
                        <p>{post.description}</p>

                        {(post.summary && post.summary.length > 0 && expanded.indexOf(post.id) == -1) ? 
                          <div style={{display:"flex", gap: "10px"}}>
                            <HoverButton                       
                                label="Read More" 
                                size="small"
                                icon={faChevronRight}
                                iconPos="right"
                                onClick={() => {                                
                                    setExpanded(produce(draft => {
                                        draft.push(post.id)
                                    }));
                                }}
                            />
                            {post.links.filter(l => l.type == "video").map((video, index) => (
                              <HoverButton                       
                                label="Watch Video" 
                                size="small"
                                color="green"
                                icon={faVideo}
                                iconPos="left"
                                onClick={() => {                                
                                    setVideoPost(video);
                                }}
                              />
                            ))}
                          </div>
                        :
                            <div>
                                <div dangerouslySetInnerHTML={{__html: post.summary}} />

                                {(post.links.filter(l => l.type == "url").length > 0) && 
                                    <Links>
                                        {post.links.map((link, index) => (
                                            <a key={index} href={link.url}>
                                              <FontAwesomeSvgIcon icon={faGlobeAmericas} />
                                              &nbsp;
                                              {link.url}
                                            </a>
                                        ))} 
                                    </Links>                           
                                }

                                {(post.links.filter(l => l.type == "video").length > 0) && 
                                    <Videos>
                                        {post.links.map((link, index) => (
                                          <VideoShort 
                                              title={link.title}
                                              video={link.url}
                                          />
                                        ))} 
                                    </Videos>                           
                                }
                            </div>
                        }                        
                      </div>
                  </Post>
                ))}
            </Posts>
          </div>
          <Categories>
            <h3>Categories</h3>
            <ul>
              {categories.map(({category}) => (
                <li onClick={() => setCategoryId(category.category_id)} className={categoryId == category.category_id ? "selected":""}>
                  {category.title} ({category.posts})
                </li>
              ))}
              <li onClick={() => setCategoryId(0)} className={categoryId == 0 ? "selected":""}>
                All Posts ({formattedPosts.length})
              </li>
            </ul>
          </Categories>
        </Layout2Columns> 
      </Page>

      {videoPost && 
        <>
          <Overlay onClick={() => setVideoPost(null)} />
          <div style={{position:"fixed", zIndex: "3000000001", left:"0xp", right:"0px", top:"0px", bottom: "0px"}}>
            <FontAwesomeSvgIcon 
              icon={faTimesCircle} 
              size="3x" 
              style={{
                position: "absolute",
                right: "20px",
                top: "20px"
              }}
              onClick={() => setVideoPost(null)}
            />
            <div style={{
                position: "fixed",
                left: "10%",
                top: "10%",
                right: "10%",
                bottom: "10%",
            }}>
              <VideoShort
                  title={videoPost.title}
                  video={videoPost.url}
                  size="fullscreen"
              />  
            </div> 
          </div>            
        </>
      }
    </>
  )
}

export default BlogPage