import React from "react"
import styled from "styled-components"

import { parseOutVideoId } from "../utilities/utils"

const VideoContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    
    iframe {
        height: ${props => props.fullscreen ? "100%":"530px"};
    }
`

function Video({
  video,
  title,
  fullscreen,
}) {
  const videoUrl = video ? "https://www.youtube.com/embed/" + parseOutVideoId(video) + "?autoplay=0" : null;

  return (
    <VideoContainer className="video" fullscreen={fullscreen}>
        <iframe 
            src={videoUrl} 
            width={300} 
            height={530} 
            title={title} 
            frameBorder='0' 
            allowFullScreen></iframe>
    </VideoContainer>
  );
}

export default Video